<template>
  <div class="vg_wrapper">
    <el-row class="vd_mar10">
      <el-col :md="16">
        <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
        <el-button class="vg_mr_8" size="mini" type="primary" @click="confirmIn">确认选择</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <search-table
          ref="multiTable"
          :data="tableData"
          :tableRowKey="'oinv_id'"
          :columns="tableProperties"
          v-loading="loadFlag"
          :totalPage="totalPage"
          @selection-change="handleSelectionChange"
          @getTableData="getProdsList"
        >
        </search-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, getNoCatch } from '@api/request';
import { stffAPI } from '@api/modules/staff';
import { suppAPI } from '@api/modules/supp';
import { custAPI } from '@api/modules/cust';
import pubPagination from '@/components/common/pubPagination';
import { useCpttMixin } from '@/assets/js/mixin/initMixin';
import { podrAPI } from '@api/modules/podr';
import SearchTable from '@/components/table/SearchTableV2.vue';

export default {
  name: 'ImportPinv',
  components: {
    SearchTable,
    pubPagination
  },
  mixins: [useCpttMixin],
  data() {
    return {
      tableProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        { label: '采购合同号', prop: 'podr_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '货款金额', prop: 'podr_prtotal', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: false, sortable: false, widthAuto: true }
      ],
      tableData: [],
      searchForm: {
        prod_no: null,
        supp_id: null,
        prod_name: null,
        cust_id: null,
        stff_id: null
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      suppList: [],
      custList: [],
      stffUserList: []
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getProdsList();
      this.getSupp();
      this.getStffUser();
      this.getCust();
    },
    getProdsList() {
      getNoCatch(podrAPI.getPodrList, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag = false;
      });
    },
    //获取客户信息
    getCust() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(res => {
          this.$message.error(res.data.code);
        });
    },
    //获取录取人信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.prod_id;
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        prod_no: null,
        supp_id: null,
        prod_name: null,
        cust_id: null,
        stff_id: null
      };
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getProdsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getProdsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childChanelProd');
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childConfirmProd', this.multiSelection);
        this.clear();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_dis_right {
  display: flex;
  justify-content: flex-end;
}
</style>
