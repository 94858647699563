<template>
  <div class="vg_wrapper">
    <el-row class="vd_mar10">
      <el-col :md="16">
        <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
        <el-button class="vg_mr_8" size="mini" type="primary" @click="confirmIn">确认选择</el-button>
        <el-button class="vg_mr_8" size="mini" type="primary" @click="buttonRefresh">刷新</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <search-table
          ref="multiTable"
          :data="tableData"
          :tableRowKey="'oinv_id'"
          :columns="tableProperties"
          v-loading="loadFlag"
          :totalPage="totalPage"
          @selection-change="handleSelectionChange"
          @getTableData="getProdsList"
          :pageSize="50"
        >
        </search-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getNoCatch } from '@api/request';
import { useCpttMixin } from '@/assets/js/mixin/initMixin';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { oinvAPI } from '@api/modules/oinv';

export default {
  name: 'ImportPinv',
  components: {
    SearchTable
  },
  mixins: [useCpttMixin],
  data() {
    return {
      tableProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '订单号', prop: 'scon_cust_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '合同金额', prop: 'scon_total', itemType: 'input', input: false, sortable: false, widthAuto: true }
      ],
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: []
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getProdsList();
    },
    getProdsList() {
      getNoCatch(oinvAPI.getSconProdList, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag = false;
      });
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.prod_id;
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.loadFlag = true;
      this.initData();
    },
    // 查询方法
    getProdsNow() {
      this.loadFlag = true;
      this.initData();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childChanelScon');
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        let list = [];
        this.multiSelection.forEach(item => {
          list = list.concat(item.oinv_part_list);
        });
        this.$emit('childConfirmScon', list);
        this.clear();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

.vd_dis_right {
  display: flex;
  justify-content: flex-end;
}
</style>
