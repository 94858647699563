<template>
  <div class="vg_wrapper">
    <!--<add-header @submit="submit('oinvForm')" @cancel="cancel" :oinveFlag="true"></add-header>-->
    <AddHeader @submit="submit('oinvForm')" @cancel="cancel">
      <span slot="otherButton" class="vg_mr_8 flexHV">
        单据号:
        <el-input size="mini" style="width: 150px" disabled v-model="oinvForm.oinv_no" show-word-limit> </el-input>
      </span>
    </AddHeader>
    <el-form ref="oinvForm" :model="oinvForm" :rules="rules" label-width="120px" size="mini">
      <el-row class="vg_mb_5" style="flex-wrap: wrap" type="flex">
        <el-col :span="24" class="vg_mb_5">
          <el-form-item label="采购类型" prop="procurement_type">
            <el-radio-group v-model="oinvForm.procurement_type">
              <el-radio @change="typeChange" :label="0">非进口</el-radio>
              <el-radio @change="typeChange" :label="1">进口</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="vg_mb_5">
          <el-form-item label="开票供应商" prop="supp_id">
            <el-select filterable v-model="oinvForm.supp_id" placeholder="请选择开票供应商" size="small" clearable>
              <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="vg_mb_5">
          <el-form-item label="发票号码" prop="oinv_actu_nos">
            <el-input v-model="oinvForm.oinv_actu_nos" maxlength="30" show-word-limit placeholder="请填写发票号码"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="vg_mb_5">
          <el-form-item label="发票日期" prop="oinv_date">
            <el-date-picker v-model="oinvForm.oinv_date" type="date" placeholder="选择发票日期"> </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="vg_mb_5">
          <el-form-item label="发票金额" prop="oinv_invtotal">
            <el-input v-model="oinvForm.oinv_invtotal" disabled placeholder="请填写发票金额"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="vg_mb_5">
          <el-form-item label="币种" prop="cust_currency">
            <el-select v-model="oinvForm.cust_currency" size="small" placeholder="请选择币种" filterable>
              <el-option v-for="item in CompanyList" :key="item.id" :value="item.param1" :label="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="vg_mb_5">
          <el-form-item label="汇率" prop="tran_rmbrate">
            <el-input
              v-model="oinvForm.tran_rmbrate"
              @change="calcSconRmbtotal"
              @input="val => (oinvForm.tran_rmbrate = keep4Decimal(val))"
              maxlength="7"
              show-word-limit
              placeholder="请填写汇率"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="oinvForm.procurement_type === 0" :span="8" class="vg_mb_5">
          <el-form-item label="本币金额" prop="scon_rmbtotal">
            <el-input v-model="oinvForm.scon_rmbtotal" disabled placeholder="请填写本币金额"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="vg_mb_5">
          <el-form-item label="发票种类" prop="oinv_invtype">
            <el-select v-model="oinvForm.oinv_invtype" size="small" placeholder="请选择发票种类" filterable>
              <el-option v-for="item in invtypeList" :key="item.id" :value="item.id" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="oinvForm.procurement_type === 1" :span="8" class="vg_mb_5">
          <el-form-item label="关税金额" prop="tariff_price">
            <el-input
              v-model="oinvForm.tariff_price"
              maxlength="11"
              placeholder="请填写"
              show-word-limit
              @input="val => (oinvForm.tariff_price = keep2Decimal(val))"
              @change="linkageComputingPart"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="oinvForm.procurement_type === 1" :span="8" class="vg_mb_5">
          <el-form-item label="关税汇率" prop="tariff_rate">
            <el-input
              v-model="oinvForm.tariff_rate"
              maxlength="15"
              placeholder="请填写"
              show-word-limit
              @input="val => (oinvForm.tariff_rate = keepNDecimal(val, 9))"
              @change="linkageComputingPart"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="vg_mb_5">
          <el-form-item label="源单类型" prop="oinv_type">
            <el-select v-model="oinvForm.oinv_type" size="small" placeholder="请选择源单类型" filterable>
              <el-option v-for="item in OinvTypeList" :key="item.id" :value="item.id" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="oinvForm.procurement_type === 0" :span="8" class="vg_mb_5">
          <el-form-item label="采购合同号" prop="podr_no">
            <el-input disabled :value="podr_nos" maxlength="10" show-word-limit placeholder="请填写采购合同号"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="vg_mb_5">
          <el-form-item label="公司抬头" prop="oinv_cptt_id">
            <el-select v-model="oinvForm.oinv_cptt_id" size="small" placeholder="请选择公司抬头" filterable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="oinvForm.procurement_type === 0" :span="8" class="vg_mb_5">
          <el-form-item label="税额合计" prop="oinv_taxtotal">
            <el-input v-model="oinvForm.oinv_taxtotal" disabled placeholder="请填写税额合计"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="vg_mb_5">
          <el-form-item label="收票日期" prop="oinv_recdate">
            <el-date-picker v-model="oinvForm.oinv_recdate" type="date" placeholder="选择收票日期"> </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="vg_mb_5">
          <el-form-item label="所属部门" prop="oinv_dept_id">
            <el-select v-model="oinvForm.oinv_dept_id" size="small" placeholder="请选择所属部门" filterable>
              <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="24">
          <el-form-item label="备注" prop="oinv_remark">
            <el-input
              v-model="oinvForm.oinv_remark"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              maxlength="255"
              show-word-limit
              placeholder="请填写备注"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col>
          <div class="vg_mb_16">
            <el-button type="primary" plain size="small" @click="dialogTableVisible = true" v-if="oinvForm.procurement_type === 0">导入成品采购合同</el-button>
            <el-button type="primary" plain size="small" @click="sconDialogTableVisible = true" v-if="oinvForm.procurement_type === 1">导入销售合同</el-button>
            <!-- <el-button type="success" plain size="small" @click="dialogTableVisible1 = true;">导入辅助信息</el-button> -->
            <el-button type="danger" plain size="small" @click="delOnivPartList">删除</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <OinvPartList :oinvForm="oinvForm" @handleSelectionChange="handleSelectionChange" @calcMainChange="calcMainChange"></OinvPartList>
        </el-col>
      </el-row>
      <el-dialog title="导入销售合同" width="70%" :visible.sync="sconDialogTableVisible">
        <ImportScon @childConfirmScon="childConfirmScon" @childChanelScon="childChanelScon"></ImportScon>
      </el-dialog>
      <el-dialog title="导入成品采购合同" width="70%" :visible.sync="dialogTableVisible">
        <ImportProd @childConfirmProd="childConfirmProd" @childChanelProd="childChanelProd"></ImportProd>
      </el-dialog>
      <el-dialog title="导入辅助信息" width="70%" :visible.sync="dialogTableVisible1">
        <ImportMtrb @childConfirmMtrb="childConfirmMtrb" @childChanelMtrb="childChanelMtrb"></ImportMtrb>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { oinvAPI } from '@api/modules/oinv';
import { cpttAPI } from '@api/modules/comptitle';
import { suppAPI } from '@/api/modules/supp';
import { optnAPI } from '@api/modules/optn';
import { deptAPI } from '@api/modules/department';
import AddHeader from '@/views/component/addHeader.vue';
import inputUser from '@/views/component/inputUser';
import { BigNumber } from 'bignumber.js';
import OinvPartList from './Component/OinvPartList.vue';
import ImportProd from './Component/ImportProd.vue';
import ImportMtrb from './Component/ImportMtrb.vue';
import { arrayDeRepeat, getArrayIds, objectArrayReduce } from '@assets/js/arrayUtils';
import { keep2Decimal, keep4Decimal, keepNDecimal } from '@assets/js/regExUtil';
import ImportScon from '@/views/SettleManagement/OinvManage/TabChild/Component/ImportScon.vue';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'OinvAddMain',
  components: {
    ImportScon,
    AddHeader,
    inputUser,
    OinvPartList,
    ImportProd,
    ImportMtrb
  },
  data() {
    return {
      rules: {
        supp_id: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        oinv_actu_nos: [{ required: true, trigger: 'blur', message: ' ' }],
        // oinv_paydate: [{ required: true, trigger: 'blur', message: ' ' }],
        oinv_type: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        oinv_cptt_id: [{ required: true, trigger: ['blur', 'change'], message: ' ' }]
      },
      oinvForm: {
        supp_id: null,
        oinv_actu_nos: null,
        oinv_invtotal: null,
        oinv_date: new Date(),
        cust_currency: 'RMB',
        tran_rmbrate: '1.0000',
        scon_rmbtotal: null,
        oinv_invtype: 1,
        oinv_paydate: new Date(),
        oinv_type: 1,
        podr_no: null,
        oinv_cptt_id: null,
        oinv_taxtotal: null,
        oinv_recdate: new Date(),
        oinv_dept_id: this.$cookies.get('userInfo').dept_id,
        oinv_remark: null,
        oinv_part_list: [],
        procurement_type: 0
      },
      custOptionList: [],
      cpttList: [],
      suppList: [],
      CompanyList: [],
      deptList: [],
      invtypeList: [
        { id: 1, label: '增值税发票' },
        { id: 2, label: '普通发票' }
      ],
      OinvTypeList: [
        { id: 1, label: '内贸采购' },
        { id: 2, label: '委外加工' }
      ],
      dialogTableVisible: false,
      dialogTableVisible1: false,
      sconDialogTableVisible: false,
      selectList: []
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  computed: {
    podr_nos() {
      let podrNos = getArrayIds(this.oinvForm.oinv_part_list, 'podr_no');
      this.oinvForm.podr_no = arrayDeRepeat(podrNos).toString();
      return this.oinvForm.podr_no;
    }
  },
  methods: {
    keepNDecimal,
    keep2Decimal,
    keep4Decimal,
    initData() {
      this.getCptt();
      this.getSupp();
      this.getCustDport();
      this.getDept();
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let oinvForm = JSON.parse(JSON.stringify(this.oinvForm));
      oinvForm = Object.assign(oinvForm, staffForm);
      oinvForm.oinv_paydate = parseInt(Number(new Date(oinvForm.oinv_paydate).getTime()) / 1000);
      if (oinvForm.oinv_date) {
        oinvForm.oinv_date = parseInt(Number(new Date(oinvForm.oinv_date).getTime()) / 1000);
      }
      if (oinvForm.oinv_recdate) {
        oinvForm.oinv_recdate = parseInt(Number(new Date(oinvForm.oinv_recdate).getTime()) / 1000);
      }
      post(oinvAPI.addOinv, oinvForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump('/oinv_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });

            this.resetForm('oinvForm');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('oinvForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 删除
    delOnivPartList() {
      if (this.selectList.length < 1) {
        return this.$message.warning('至少选中一条数据');
      }
      let temp = [];
      this.selectList.forEach(item => {
        temp.push(item.key);
      });
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let i of temp) {
        this.oinvForm.oinv_part_list.splice(i, 1);
      }
      for (let i = 0; i < this.oinvForm.oinv_part_list.length; i++) {
        this.oinvForm.oinv_part_list[i].key = Number(i);
      }
      this.calcMainChange();
    },
    // 关闭辅料信息弹框
    childChanelMtrb() {
      this.dialogTableVisible1 = false;
    },
    // 确认导入辅料信息
    childConfirmMtrb(val) {
      let list = JSON.parse(JSON.stringify(val));
      list = list.filter(item => this.oinvForm.oinv_part_list.every(item1 => item.mtrb_id !== item1.mtrb_id));
      for (let i = 0; i < list.length; i++) {
        list[i].prod_price = this.helper.haveFour(list[i].mtrb_price);
        list[i].prod_hsinrate = this.helper.haveFour(list[i].mtrb_inrate);
        list[i].prod_no = list[i].mtrb_no;
        list[i].prod_bhsname = list[i].mtrb_name;
        list[i].prod_unit = list[i].mtrb_unit;
        list[i].oinv_part_num = null;
        list[i].oinv_part_subtotal = null;
        list[i].oinv_actu_tax = null;
        list[i].oinv_part_type = 1;
      }
      this.oinvForm.oinv_part_list = this.oinvForm.oinv_part_list.concat(list);
      for (let i = 0; i < this.oinvForm.oinv_part_list.length; i++) {
        this.oinvForm.oinv_part_list[i].key = Number(i);
      }
      this.dialogTableVisible1 = false;
    },
    // 关闭商品信息弹框
    childChanelProd() {
      this.dialogTableVisible = false;
    },
    // 确认导入商品信息
    childConfirmProd(val) {
      let list = JSON.parse(JSON.stringify(val));
      list = list.filter(item => this.oinvForm.oinv_part_list.every(item1 => item.podr_id !== item1.podr_id));
      let temp = [];
      list.forEach(item => {
        item.podr_part_list.forEach(subItem => {
          let obj = {};
          obj.procurement_type = this.oinvForm.procurement_type;
          obj.podr_no = item.podr_no;
          obj.prod_cust_no = subItem.prod_cust_no;
          obj.prod_no = subItem.prod_no;
          obj.prod_bhsname = subItem.prod_name;
          obj.oinv_part_num = subItem.podr_part_num;
          obj.prod_price = subItem.prod_price;
          obj.prod_unit = subItem.prod_unit;
          obj.oinv_part_subtotal = new BigNumber(subItem.podr_part_num).times(subItem.prod_price).toFixed(4);
          obj.oinv_actu_tax = new BigNumber(obj.oinv_part_subtotal).div(1.13).times(0.13).toFixed(2);
          obj.prod_hsinrate = 13;
          obj.oinv_part_type = 1;
          temp.push(obj);
        });
      });
      this.oinvForm.oinv_part_list = this.oinvForm.oinv_part_list.concat(temp);
      for (let i = 0; i < this.oinvForm.oinv_part_list.length; i++) {
        this.oinvForm.oinv_part_list[i].key = Number(i);
      }
      this.calcMainChange();
      this.dialogTableVisible = false;
    },
    // 子表勾选
    handleSelectionChange(val) {
      this.selectList = JSON.parse(JSON.stringify(val));
    },
    // 计算主表回显值
    calcMainChange() {
      let totalA = new BigNumber(0);
      let totalB = new BigNumber(0);
      let totalC = new BigNumber(0);
      for (let i = 0; i < this.oinvForm.oinv_part_list.length; i++) {
        totalA = totalA.plus(this.oinvForm.oinv_part_list[i].oinv_part_subtotal ? this.oinvForm.oinv_part_list[i].oinv_part_subtotal : 0);
        totalB = totalB.plus(this.oinvForm.oinv_part_list[i].oinv_actu_tax ? this.oinvForm.oinv_part_list[i].oinv_actu_tax : 0);
        totalC = totalC.plus(this.oinvForm.oinv_part_list[i].usd_money ? this.oinvForm.oinv_part_list[i].usd_money : 0);
      }
      totalC = totalC.toNumber();
      totalA = totalA.toNumber();
      totalB = totalB.toNumber();
      //进口回显美金金额合计 非进口回显金额合计
      if (this.oinvForm.procurement_type === 0) {
        this.oinvForm.oinv_invtotal = this.helper.haveFour(totalA);
      } else {
        this.oinvForm.oinv_invtotal = this.helper.haveFour(totalC);
      }
      this.oinvForm.oinv_taxtotal = this.helper.haveFour(totalB);
      this.calcSconRmbtotal();
    },
    // 计算本币金额
    calcSconRmbtotal() {
      let totalA = new BigNumber(1);
      totalA = totalA.times(this.oinvForm.oinv_invtotal ? this.oinvForm.oinv_invtotal : 0).times(this.oinvForm.tran_rmbrate ? this.oinvForm.tran_rmbrate : 0);
      totalA = totalA.toNumber();
      this.oinvForm.scon_rmbtotal = this.helper.haveFour(totalA);
    },
    // 获取部门
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取币种
    getCustDport() {
      get(optnAPI.getOptnByPermId, { perm_id: 10015 })
        .then(res => {
          if (res.data.code === 0) {
            this.CompanyList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            this.oinvForm.oinv_cptt_id = this.cpttList.find(({ cptt_name }) => cptt_name === '上海凡爱宠物用品有限公司').cptt_id;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.oinvForm.oinv_part_list = [];
    },
    //切换类型清空子表
    typeChange() {
      this.oinvForm.oinv_part_list = [];
    },
    // 关闭商品信息弹框
    childChanelScon() {
      this.sconDialogTableVisible = false;
    },
    //添加销售合同数据
    childConfirmScon(val) {
      let list = JSON.parse(JSON.stringify(val));
      list.forEach(item => {
        item.procurement_type = this.oinvForm.procurement_type;
        item.usd_money = this.helper.haveFour(Number(item.oinv_part_num) * Number(item.usd_price)); //计算美金金额 保留四位截取
        item.tariff_scon_price = new BigNumber(Number(item.usd_price) * Number(this.oinvForm.tariff_rate)).toFixed(2); //计算进口商品采购价￥
      });
      this.oinvForm.oinv_part_list = this.oinvForm.oinv_part_list.concat(list);
      let tariff_scon_priceSum = objectArrayReduce(this.oinvForm.oinv_part_list, 'tariff_scon_price'); //进口商品采购价合计
      let usd_moneySum = objectArrayReduce(this.oinvForm.oinv_part_list, 'usd_money'); //美金金额合计
      this.oinvForm.oinv_part_list.forEach(item => {
        item.import_tariff = new BigNumber((Number(this.oinvForm.tariff_price) / Number(usd_moneySum)) * Number(item.tariff_scon_price)).toFixed(2); //计算关税￥ = 主表关税金额 / 子表美金金额合计 * 子表进口商品采购价￥
        item.import_tariff_usd = new BigNumber(Number(item.import_tariff) / Number(this.oinvForm.tariff_rate)).toFixed(2); //计算关税$
        item.oinv_part_subtotal = new BigNumber(Number(item.tariff_scon_price) + Number(item.import_tariff)).toFixed(2); //计算进口商品采购成本￥
        item.import_procurement_cost = Number(item.usd_money) + Number(item.import_tariff_usd); //计算进口商品采购成本$
        item.import_procurement_price = new BigNumber(Number(item.import_procurement_cost) / Number(item.oinv_part_num)).toFixed(6); //计算进口商品采购单价$
        item.prod_price = new BigNumber(Number(item.oinv_part_subtotal) / Number(item.oinv_part_num)).toFixed(4); //计算进口商品采购单价￥
      });
      //计算key
      for (let i = 0; i < this.oinvForm.oinv_part_list.length; i++) {
        this.oinvForm.oinv_part_list[i].key = Number(i);
        //格式化NAN值
        //使用对象解构和循环遍历属性名，应用formatNaNOrZero函数
        const propertiesToFormat = [
          'usd_money',
          'tariff_scon_price',
          'import_tariff',
          'import_tariff_usd',
          'oinv_part_subtotal',
          'import_procurement_cost',
          'import_procurement_price',
          'prod_price'
        ];
        propertiesToFormat.forEach(prop => {
          this.oinvForm.oinv_part_list[i][prop] = isNaN(this.oinvForm.oinv_part_list[i][prop]) ? 0 : this.oinvForm.oinv_part_list[i][prop];
        });
      }
      this.sconDialogTableVisible = false;
      //计算
      this.calcMainChange();
    },
    async linkageComputingPart() {
      //先计算出进口商品采购价￥
      await this.oinvForm.oinv_part_list.forEach(item => {
        item.tariff_scon_price = new BigNumber(Number(item.usd_price) * Number(this.oinvForm.tariff_rate)).toFixed(2); //计算进口商品采购价￥
      });
      //再计算合计
      let tariff_scon_priceSum = await objectArrayReduce(this.oinvForm.oinv_part_list, 'tariff_scon_price'); //进口商品采购价合计
      let usd_moneySum = objectArrayReduce(this.oinvForm.oinv_part_list, 'usd_money'); //美金金额合计
      //再用合计计算
      await this.oinvForm.oinv_part_list.forEach(item => {
        item.import_tariff = new BigNumber((Number(this.oinvForm.tariff_price) / Number(usd_moneySum)) * Number(item.tariff_scon_price)).toFixed(2); //计算关税￥ = 主表关税金额 / 子表美金金额合计 * 子表进口商品采购价￥
        item.import_tariff_usd = new BigNumber(Number(item.import_tariff) / Number(this.oinvForm.tariff_rate)).toFixed(2); //计算关税$
        item.oinv_part_subtotal = new BigNumber(Number(item.tariff_scon_price) + Number(item.import_tariff)).toFixed(2); //计算进口商品采购成本￥
        item.import_procurement_cost = Number(item.usd_money) + Number(item.import_tariff_usd); //计算进口商品采购成本$
        item.import_procurement_price = new BigNumber(Number(item.import_procurement_cost) / Number(item.oinv_part_num)).toFixed(6); //计算进口商品采购单价$
        item.prod_price = new BigNumber(Number(item.oinv_part_subtotal) / Number(item.oinv_part_num)).toFixed(4); //计算进口商品采购单价￥
        //使用对象解构和循环遍历属性名，应用formatNaNOrZero函数
        const propertiesToFormat = [
          'usd_money',
          'tariff_scon_price',
          'import_tariff',
          'import_tariff_usd',
          'oinv_part_subtotal',
          'import_procurement_cost',
          'import_procurement_price',
          'prod_price'
        ];
        propertiesToFormat.forEach(prop => {
          item[prop] = isNaN(item[prop]) ? 0 : item[prop];
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #c2c2c2;
  margin: 10px 0;
}
</style>
