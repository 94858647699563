<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="initData()">
        <el-row>
          <el-col :md="8">
            <el-form-item label="材料编号:" prop="mtrb_no">
              <el-input size="small" v-model.trim="searchForm.mtrb_no" clearable placeholder="请输入材料编号"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="中文名称:" prop="mtrb_name">
              <el-input size="small" v-model.trim="searchForm.mtrb_name" clearable placeholder="请输入中文名称"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="供应商简称:" prop="supp_id">
              <el-select size="small" filterable v-model="searchForm.supp_id" placeholder="请选择供应商简称">
                <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="14">
            <el-form-item label="录入人:" prop="stff_id">
              <el-select filterable v-model="searchForm.stff_id" placeholder="请选择录入人" size="small" clearable>
                <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getMtrbsNow()" class="vg_ml_16">查询 </el-button>
              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                ><i class="el-icon-refresh-right"></i> 刷新</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24" class="vd_dis_right"> </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="vd_button_group vg_mtb_16">
      <el-row class="vd_mar10">
        <el-col :md="16">
          <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
          <el-button class="vg_mr_8" size="mini" type="primary" @click="confirmIn">确认选择</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :md="24">
        <el-table
          class="vg_cursor"
          ref="multiTable"
          :data="tableData"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          v-loading="loadFlag"
        >
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="序号" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="材料编号" prop="mtrb_no"> </el-table-column>
          <el-table-column label="中文名称" prop="mtrb_name" show-overflow-tooltip> </el-table-column>
          <el-table-column label="供应商" prop="supp_abbr"> </el-table-column>
          <el-table-column label="录入人" prop="stff_name"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mtrbAPI } from '@/api/modules/mtrb';
import { stffAPI } from '@api/modules/staff';
import { suppAPI } from '@api/modules/supp';
import pubPagination from '@/components/common/pubPagination';
import { useCpttMixin } from '@/assets/js/mixin/initMixin';
export default {
  name: 'ImportPinv',
  components: {
    pubPagination
  },
  mixins: [useCpttMixin],
  data() {
    return {
      tableData: [],
      searchForm: {
        mtrb_no: null,
        supp_id: null,
        mtrb_name: null,
        cust_id: null,
        stff_id: null
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      suppList: [],
      stffUserList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getMtrbsList();
      this.getSupp();
      this.getStffUser();
    },
    getMtrbsList() {
      get(mtrbAPI.getMtrbs, {
        supp_id: this.searchForm.supp_id,
        mtrb_no: this.searchForm.mtrb_no,
        mtrb_name: this.searchForm.mtrb_name,
        stff_id: this.searchForm.stff_id,
        page_no: this.currentPage,
        status: 2
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    //获取录取人信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.mtrb_id;
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        mtrb_no: null,
        supp_id: null,
        mtrb_name: null,
        stff_id: null
      };
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getMtrbsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getMtrbsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childChanelMtrb');
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childConfirmMtrb', this.multiSelection);
        this.clear();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_dis_right {
  display: flex;
  justify-content: flex-end;
}
</style>
